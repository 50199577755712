<div class="details-box">
  <div id="intro" class="intro">
    <span class="wave">👋</span> Hello, I'm Karan!
  </div>

  <div id="about" class="about">
    Developer | Creator | Wanderer
  </div>

  <app-connect></app-connect>
</div>
