import {Component} from '@angular/core';
import {BroadCasterEvents, BroadcasterService} from './core/services/broadcaster.service';
import {noop} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Karan Budhwani';

  mode = Mode.LITE_MODE;
  modeType = Mode;
  resumeUrl = RESUME_URL_DRIVE;

  constructor(private broadcasterService: BroadcasterService) {
    window.addEventListener('mousemove', this.setTrailPosition);
    this.listenToBroadcasterEvents();
  }

  private listenToBroadcasterEvents(): void {
    this.broadcasterService.on(BroadCasterEvents.DARK_MODE).subscribe(_ => {
      this.mode = Mode.DARK_MODE;
      this.setBackgroundColor(BackgroundColor.DARK_MODE);
      this.setTrailBackgroundColor(TrailBackgroundColor.DARK_MODE);
      this.setButtonFontColor(ButtonTextColor.DARK_MODE);
      document?.getElementById('button-text')?.classList?.add('a-after-yellow');
    });
    this.broadcasterService.on(BroadCasterEvents.LITE_MODE).subscribe(_ => {
      this.mode = Mode.LITE_MODE;
      this.setBackgroundColor(BackgroundColor.LITE_MODE);
      this.setTrailBackgroundColor(TrailBackgroundColor.LITE_MODE);
      this.setButtonFontColor(ButtonTextColor.LITE_MODE);
      document?.getElementById('button-text')?.classList?.remove('a-after-yellow');
    });
  }

  private setBackgroundColor(color: BackgroundColor): void {
    document.body.style.backgroundColor = color;
  }
  private setButtonFontColor(color: ButtonTextColor): void {
    document.getElementById('button-text').style.color = color;
    document.getElementById('button-border-right').style.background = color;
    document.getElementById('button-border-top').style.background = color;
    document.getElementById('button-border-left').style.background = color;
    document.getElementById('button-border-bottom').style.background = color;
  }

  private setTrailBackgroundColor(color: TrailBackgroundColor): void {
    const cursorTrail = document.getElementById('cursor-trail');
    !!cursorTrail ? cursorTrail.style.background = color : noop();
  }

  private setTrailPosition(event: MouseEvent): void {
    const left = event?.clientX;
    const top = event?.clientY;
    const cursorTrail = document.getElementById('cursor-trail');
    !!cursorTrail ? cursorTrail.style.display = `unset` : noop();
    !!cursorTrail ? cursorTrail.style.transform = `translate3d(${left - 5}px, ${top - 5}px, 0)` : noop();
  }

  onBgToggle(event: any): void {
    const checked = event?.target?.checked;
    this.broadcasterService.broadcast(!!checked ? BroadCasterEvents.DARK_MODE : BroadCasterEvents.LITE_MODE);
  }
}

export enum BackgroundColor {
  DARK_MODE = '#0D1117',
  LITE_MODE = '#FAFAFA'
}

export enum TrailBackgroundColor {
  DARK_MODE = '#FAED26',
  LITE_MODE = '#1b88c2'
}

export enum ButtonTextColor {
  DARK_MODE = '#FAED26',
  LITE_MODE = '#1b88c2'
}

export enum Mode {
  DARK_MODE,
  LITE_MODE
}

export const RESUME_URL_AWS = 'https://depository-store.s3.us-east-2.amazonaws.com/resume_karan_budhwani.pdf';

export const RESUME_URL_DRIVE = 'https://drive.google.com/drive/folders/166ERLXvsn31jzNH08jU0h8dcA1NFbEkc?usp=sharing';

export const WEBFOLIO_URL = 'https://webfolio.heykaran.in';
