import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {BroadcastEvent} from '../models/broadcaster-event';
import {filter, map} from 'rxjs/operators';

@Injectable()
export class BroadcasterService {
  private eventBus: BehaviorSubject<BroadcastEvent>;

  constructor() {
    this.eventBus = new BehaviorSubject<BroadcastEvent>({key: '', data: ''});
  }

  broadcast(key: string, data?: any): any {
    this.eventBus.next({key, data});
  }

  on<T>(key: string): Observable<T> {
    return this.eventBus.asObservable().pipe(
      filter(event => event.key === key),
      map(event => event.data as T));
  }
}

export class BroadCasterEvents {
  static DARK_MODE = 'DARK MODE';
  static LITE_MODE = 'LITE MODE';
}
