import {HammerGestureConfig} from '@angular/platform-browser';
import {Injectable} from '@angular/core';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
   overrides = {
     pinch: {enable: false},
     rotate: {enable: false}
   } as any;
}
