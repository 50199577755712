import {Component, Input, OnInit} from '@angular/core';
import {Mode} from '../app.component';
import {BroadCasterEvents, BroadcasterService} from '../core/services/broadcaster.service';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']
})
export class ConnectComponent implements OnInit {

  mode = Mode.LITE_MODE;
  modeType = Mode;
  githubProfile = GITHUB_PROFILE;
  emailAddress = EMAIL_ADDRESS;
  stackoverflowProfile = STACKOVERFLOW_PROFILE;
  linkedinProfile = LINKEDIN_PROFILE;
  constructor(private broadcasterService: BroadcasterService) {
    this.listenToBroadcasterEvents();
  }

  ngOnInit(): void {
  }
  private listenToBroadcasterEvents(): void {
    this.broadcasterService.on(BroadCasterEvents.DARK_MODE).subscribe(_ => {
      this.mode = Mode.DARK_MODE;
    });
    this.broadcasterService.on(BroadCasterEvents.LITE_MODE).subscribe(_ => {
      this.mode = Mode.LITE_MODE;
    });
  }

}
export const GITHUB_PROFILE = 'https://github.com/karanb86';
export const EMAIL_ADDRESS = 'mailto:karanbd86@gmail.com';
export const STACKOVERFLOW_PROFILE = 'https://stackoverflow.com/users/15756864/karan-budhwani';
export const LINKEDIN_PROFILE = 'https://www.linkedin.com/in/karan-budhwani-9a6277190/';
