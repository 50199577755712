<div class="connect-wrap">
  <a [href]="githubProfile" target="_blank" rel="noopener noreferrer">
    <ng-container *ngIf="mode === modeType.LITE_MODE" [ngTemplateOutlet]="githubBlueIcon"></ng-container>
    <ng-container *ngIf="mode === modeType.DARK_MODE" [ngTemplateOutlet]="githubYellowIcon"></ng-container>
  </a>
  <a [href]="linkedinProfile" target="_blank" rel="noopener noreferrer">
    <ng-container *ngIf="mode === modeType.LITE_MODE" [ngTemplateOutlet]="linkedInBlueIcon"></ng-container>
    <ng-container *ngIf="mode === modeType.DARK_MODE" [ngTemplateOutlet]="linkedInYellowIcon"></ng-container>
  </a>
  <a class="mail-svg" [href]="emailAddress" target="_blank" rel="noopener noreferrer">
    <ng-container *ngIf="mode === modeType.LITE_MODE" [ngTemplateOutlet]="mailBlueIcon"></ng-container>
    <ng-container *ngIf="mode === modeType.DARK_MODE" [ngTemplateOutlet]="mailYellowIcon"></ng-container>
  </a>
  <a class="so-svg" [href]="stackoverflowProfile" target="_blank" rel="noopener noreferrer">
    <ng-container *ngIf="mode === modeType.LITE_MODE" [ngTemplateOutlet]="stackOverflowBlueIcon"></ng-container>
    <ng-container *ngIf="mode === modeType.DARK_MODE" [ngTemplateOutlet]="stackOverflowYellowIcon"></ng-container>
  </a>
</div>

<ng-template #githubBlueIcon>
  <svg enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 .5c-6.63 0-12 5.28-12 11.792 0 5.211 3.438 9.63 8.205 11.188.6.111.82-.254.82-.567 0-.28-.01-1.022-.015-2.005-3.338.711-4.042-1.582-4.042-1.582-.546-1.361-1.335-1.725-1.335-1.725-1.087-.731.084-.716.084-.716 1.205.082 1.838 1.215 1.838 1.215 1.07 1.803 2.809 1.282 3.495.981.108-.763.417-1.282.76-1.577-2.665-.295-5.466-1.309-5.466-5.827 0-1.287.465-2.339 1.235-3.164-.135-.298-.54-1.497.105-3.121 0 0 1.005-.316 3.3 1.209.96-.262 1.98-.392 3-.398 1.02.006 2.04.136 3 .398 2.28-1.525 3.285-1.209 3.285-1.209.645 1.624.24 2.823.12 3.121.765.825 1.23 1.877 1.23 3.164 0 4.53-2.805 5.527-5.475 5.817.42.354.81 1.077.81 2.182 0 1.578-.015 2.846-.015 3.229 0 .309.21.678.825.56 4.801-1.548 8.236-5.97 8.236-11.173 0-6.512-5.373-11.792-12-11.792z" fill="#1b88c2"/></svg>
</ng-template>
<ng-template #githubYellowIcon>
  <svg enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 .5c-6.63 0-12 5.28-12 11.792 0 5.211 3.438 9.63 8.205 11.188.6.111.82-.254.82-.567 0-.28-.01-1.022-.015-2.005-3.338.711-4.042-1.582-4.042-1.582-.546-1.361-1.335-1.725-1.335-1.725-1.087-.731.084-.716.084-.716 1.205.082 1.838 1.215 1.838 1.215 1.07 1.803 2.809 1.282 3.495.981.108-.763.417-1.282.76-1.577-2.665-.295-5.466-1.309-5.466-5.827 0-1.287.465-2.339 1.235-3.164-.135-.298-.54-1.497.105-3.121 0 0 1.005-.316 3.3 1.209.96-.262 1.98-.392 3-.398 1.02.006 2.04.136 3 .398 2.28-1.525 3.285-1.209 3.285-1.209.645 1.624.24 2.823.12 3.121.765.825 1.23 1.877 1.23 3.164 0 4.53-2.805 5.527-5.475 5.817.42.354.81 1.077.81 2.182 0 1.578-.015 2.846-.015 3.229 0 .309.21.678.825.56 4.801-1.548 8.236-5.97 8.236-11.173 0-6.512-5.373-11.792-12-11.792z" fill="#FAED26"/></svg>
</ng-template>

<ng-template #linkedInBlueIcon>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 382 382" style="enable-background:new 0 0 382 382;" xml:space="preserve">
<path style="fill:#1b88c2;" d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889
	C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056
	H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806
	c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1
	s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73
	c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079
	c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426
	c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472
	L341.91,330.654L341.91,330.654z"/>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</ng-template>
<ng-template #linkedInYellowIcon>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 382 382" style="enable-background:new 0 0 382 382;" xml:space="preserve">
<path style="fill:#FAED26;" d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889
	C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056
	H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806
	c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1
	s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73
	c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079
	c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426
	c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472
	L341.91,330.654L341.91,330.654z"/>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</ng-template>

<ng-template #mailBlueIcon>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path fill="#1b88c2" d="M467,76H45C20.137,76,0,96.262,0,121v270c0,24.885,20.285,45,45,45h422c24.655,0,45-20.03,45-45V121
			C512,96.306,491.943,76,467,76z M460.698,106c-9.194,9.145-167.415,166.533-172.878,171.967c-8.5,8.5-19.8,13.18-31.82,13.18
			s-23.32-4.681-31.848-13.208C220.478,274.284,64.003,118.634,51.302,106H460.698z M30,384.894V127.125L159.638,256.08L30,384.894z
			 M51.321,406l129.587-128.763l22.059,21.943c14.166,14.166,33,21.967,53.033,21.967c20.033,0,38.867-7.801,53.005-21.939
			l22.087-21.971L460.679,406H51.321z M482,384.894L352.362,256.08L482,127.125V384.894z"/>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</ng-template>
<ng-template #mailYellowIcon>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path fill="#FAED26" d="M467,76H45C20.137,76,0,96.262,0,121v270c0,24.885,20.285,45,45,45h422c24.655,0,45-20.03,45-45V121
			C512,96.306,491.943,76,467,76z M460.698,106c-9.194,9.145-167.415,166.533-172.878,171.967c-8.5,8.5-19.8,13.18-31.82,13.18
			s-23.32-4.681-31.848-13.208C220.478,274.284,64.003,118.634,51.302,106H460.698z M30,384.894V127.125L159.638,256.08L30,384.894z
			 M51.321,406l129.587-128.763l22.059,21.943c14.166,14.166,33,21.967,53.033,21.967c20.033,0,38.867-7.801,53.005-21.939
			l22.087-21.971L460.679,406H51.321z M482,384.894L352.362,256.08L482,127.125V384.894z"/>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</ng-template>

<ng-template #stackOverflowBlueIcon>
  <svg id="regular" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
    <path fill="#1b88c2" d="m2 15.542v7.708c0 .414.336.75.75.75h18.5c.414 0 .75-.336.75-.75v-7.708c0-.992-1.5-.993-1.5 0v6.958h-17v-6.958c0-.992-1.5-.992-1.5 0z"/>
    <path fill="#1b88c2" d="m6.645 20.146h9.737c.992 0 .993-1.5 0-1.5h-9.737c-.992 0-.993 1.5 0 1.5z"/>
    <path fill="#1b88c2" d="m6.641 15.519c10.153 2.079 9.538 1.973 9.686 1.973.897 0 1.03-1.304.15-1.485l-9.534-1.958c-.972-.199-1.275 1.27-.302 1.47z"/>
    <path fill="#1b88c2" d="m7.854 10.326c9.121 4.524 8.692 4.421 9.026 4.421.79 0 1.04-1.069.335-1.421l-8.691-4.342c-.885-.444-1.558.898-.67 1.342z"/>
    <path fill="#1b88c2" d="m10.072 6.42 7.616 5.861c.774.597 1.712-.575.915-1.188l-7.616-5.861c-.786-.607-1.702.583-.915 1.188z"/>
    <path fill="#1b88c2" d="m20.697 9.309-5.722-7.513c-.6-.789-1.795.119-1.193.909l5.722 7.513c.596.784 1.799-.113 1.193-.909z"/>
  </svg>
</ng-template>
<ng-template #stackOverflowYellowIcon>
  <svg id="regular" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
    <path fill="#FAED26" d="m2 15.542v7.708c0 .414.336.75.75.75h18.5c.414 0 .75-.336.75-.75v-7.708c0-.992-1.5-.993-1.5 0v6.958h-17v-6.958c0-.992-1.5-.992-1.5 0z"/>
    <path fill="#FAED26" d="m6.645 20.146h9.737c.992 0 .993-1.5 0-1.5h-9.737c-.992 0-.993 1.5 0 1.5z"/>
    <path fill="#FAED26" d="m6.641 15.519c10.153 2.079 9.538 1.973 9.686 1.973.897 0 1.03-1.304.15-1.485l-9.534-1.958c-.972-.199-1.275 1.27-.302 1.47z"/>
    <path fill="#FAED26" d="m7.854 10.326c9.121 4.524 8.692 4.421 9.026 4.421.79 0 1.04-1.069.335-1.421l-8.691-4.342c-.885-.444-1.558.898-.67 1.342z"/>
    <path fill="#FAED26" d="m10.072 6.42 7.616 5.861c.774.597 1.712-.575.915-1.188l-7.616-5.861c-.786-.607-1.702.583-.915 1.188z"/>
    <path fill="#FAED26" d="m20.697 9.309-5.722-7.513c-.6-.789-1.795.119-1.193.909l5.722 7.513c.596.784 1.799-.113 1.193-.909z"/>
  </svg>
</ng-template>
