import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {CoreModule} from './core/core.module';
import { DetailsComponent } from './details/details.component';
import {HammerConfig} from './hammer.config';
import { ConnectComponent } from './connect/connect.component';

@NgModule({
  declarations: [
    AppComponent,
    DetailsComponent,
    ConnectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule
  ],
  providers: [
    {provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
